import React from 'react';
import { Typography, Card, Stack, Avatar, Divider, Badge } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import Facebook from '../../../assets/facebook.svg';
import Instagram from '../../../assets/instagram.svg';
import GBusiness from '../../../assets/google-my-business-logo.svg';
import Twitter from '../../../assets/twitter.svg';
import YouTube from '../../../assets/youtube_social_icon_red 128px.png';
import LinkedIn from '../../../assets/linked_in.svg';

const DashboardProfileStatsCard = ({ service, name, count, profilePicture, serviceName }) => {
    const theme = useTheme();

    const SmallAvatar_f = styled(Avatar)(({ }) => ({
        width: 20,
        height: 20,
        overflow: 'hidden',
        backgroundColor: '#ffffff',
      }));
    
      const SmallAvatar_i = styled(Avatar)(({ }) => ({
        width: 20,
        height: 20,
        overflow: 'hidden',
      }));
      const SmallAvatar_g = styled(Avatar)(({ }) => ({
        width: 23,
        height: 23,
        overflow: 'hidden',
      }));
    
      const SmallAvatar_t = styled(Avatar)(({ }) => ({
        width: 20,
        height: 20,
        backgroundColor: '#17A6FC',
      }));

      const SmallAvatar_l = styled(Avatar)(({ theme }) => ({
        width: 20,
        height: 20,
        backgroundColor: '#fff',
    }));

    const getAvatar = (serviceName) => {
        switch (serviceName) {
            case 'TWITTER':
                return <SmallAvatar_t alt="TWITTER" src={Twitter} />;
            case 'FACEBOOK':
                return <SmallAvatar_f alt="FACEBOOK" src={Facebook} />;
            case 'YOUTUBE':
                return <img alt="YOUTUBE" src={YouTube} />;
            case 'INSTAGRAM':
                return <SmallAvatar_i alt="INSTAGRAM" src={Instagram} />;
            case 'LINKEDIN':
                return <SmallAvatar_l alt="" src={LinkedIn} />;
            case 'GBUSINESS':
                return <SmallAvatar_g alt="GBUSINESS" src={GBusiness} variant="rounded" />;
            default:
                return null;
        }
    };

    return (
        <Card sx={{ p: theme.spacing(1), width: 'auto', display: 'flex', flexDirection: 'column' }}>
        <Stack direction="row" spacing={2} alignItems="center">
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={getAvatar(serviceName)}
                sx={{ marginRight: theme.spacing(2) }}
            >
                <Avatar
                    sx={{ bgcolor: theme.palette.primary.main }}
                    src={profilePicture || service.name}
                    alt={service.name || service.id}
                />
            </Badge>
            <Typography variant="h6">{service.name}</Typography>
        </Stack>
        <Stack direction="row" sx={{ pt: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 800 }}>
                {name} : {count}
            </Typography>
        </Stack>
    </Card>
    );
};

export default DashboardProfileStatsCard;
