import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from 'src/layouts/dashboard/footer/footer';
import FooterSection from 'src/layouts/dashboard/footer/footerSection';
import { Routes } from 'src/routes';
import MainPage from './MainPage';
import MenuBarComponent from './components/navbar/MenuBar';
import ProfileSection from './components/navbar/profile-section/ProfileSection';
import { getSettingsMenu, navSidebar } from './layouts/dashboard/leftNavigation/config';
import { CommonHelper } from './utils/commonHelper';
import { LocalStorage } from './utils/storage/local-storage';
import Header from './layouts/dashboard/header/header.component';
import MobileHeader from './layouts/dashboard/header/mobile/mobile-header.component';
import { SessionStorage } from './utils/storage/session-storage';
import Spinner from './utils/Loader/spinner';

const MainComponent = ({ isDesktop, handleInitCall }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const privateRoutes = Routes.PrivateRoute();
  const publicRoutes = Routes.PublicRoutes();

  const settingsMenu = getSettingsMenu();
  const mainMenuItems = navSidebar();

  const [processedMenuItems, setProcessedMenuItems] = useState([]);
  const [processedSettingsMenuItems, setProcessedSettingsMenuItems] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [showSideBar, setSideBar] = useState(false);
  const [headerName, setHeaderName] = useState('');
  const [isMainMenuBarView, setMainMenuBarView] = useState(true);
  const [isRouteLoaded, setRouteLoaded] = useState(false);
  const [brandPermissions, setBrandPermissions] = useState(CommonHelper.GetBrandInfoFromLocalStorage("permissions"));
  const [routes, setRoutes] = useState(publicRoutes);

  useEffect(() => {
    preProcessMenuItems();
  }, []);

  useEffect(() => {
    handleRouteLoading(false);
    handleSideBar();
  }, [location.pathname, processedMenuItems, processedSettingsMenuItems]);

  useEffect(() => {
    preProcessMenuItems();
  }, [brandPermissions]);

  const handleRouteLoading = (isRouteLoaded) => {
    setRouteLoaded(isRouteLoaded);
    LocalStorage.setItem("isRouteLoaded", JSON.stringify(isRouteLoaded));
  }

  const checkAndLoadRoutes = () => {
    const isUserLoggedIn = CommonHelper.CheckUserLoggedIn();
    if (isUserLoggedIn) {
      let allRoutes = getAllRoutes();
      setRoutes([...allRoutes]);
    }
    else {
      setRoutes([...publicRoutes]);
    }
    handleRouteLoading(true);
  }

  const getAllRoutes = () => {
    let allRoutes = privateRoutes.concat(publicRoutes);
    return allRoutes;
  }

  const handleSideBar = () => {
    if (processedMenuItems.length > 0) {
      let route = Routes.PrivateRoute().find((a) => a.path === location.pathname.replace('/', ''));
      if (route) {
        let hName = GetHeaderName(processedMenuItems);
        if (hName) {
          setMainMenuBarView(true);
          setMenuItems([...processedMenuItems]);
        } else {
          setMainMenuBarView(false);
          hName = GetHeaderName(processedSettingsMenuItems);
          setMenuItems([...processedSettingsMenuItems]);
        }
        setHeaderName(hName);
      }
      let showSidebar = !!route;
      setSideBar(showSidebar);
      checkAndLoadRoutes();
    }
    else {
      preProcessMenuItems();
    }
  };

  const GetHeaderName = (menuItems) => {
    let headerName = '';
    for (var i = 0; i < menuItems.length; i++) {
      var item = menuItems[i];
      let pathMenu = item.menu.find((a) => a.path === location.pathname);
      if (pathMenu) {
        headerName = pathMenu.title;
        break;
      }
    }
    return headerName;
  };

  const preProcessMenuItems = () => {
    if (brandPermissions) {
      let processedMenuItems = [];
      let processedSettingsMenuItems = [];
      mainMenuItems.map((menuItem) => {
        let groupMenuItems = [];
        menuItem.menu.map((item) => {
          let isShowMenuBar = checkForPermission(item.permissions);
          if (isShowMenuBar) {
            groupMenuItems.push(item);
          }
        });
        if (groupMenuItems?.length > 0) {
          let pMenuItems = {
            title: menuItem.title,
            menu: [...groupMenuItems],
          };
          processedMenuItems.push(pMenuItems);
        }
      });
      settingsMenu.map((menuItem) => {
        let groupMenuItems = [];
        menuItem.menu.map((item) => {
          let isShowMenuBar = checkForPermission(item.permissions);
          if (isShowMenuBar) {
            groupMenuItems.push(item);
          }
        });
        if (groupMenuItems?.length > 0) {
          let pMenuItems = {
            title: menuItem.title,
            menu: [...groupMenuItems],
          };
          processedSettingsMenuItems.push(pMenuItems);
        }
      });
      setProcessedMenuItems([...processedMenuItems]);
      setProcessedSettingsMenuItems([...processedSettingsMenuItems]);
    }
    else {
      handleRouteLoading(true);
    }
  };

  const checkForPermission = (permissions) => {
    let isPermissionAvailable = false;
    if (permissions) {
      if (brandPermissions[permissions]) {
        isPermissionAvailable = true;
      }
      else {
        isPermissionAvailable = false;
      }
    }
    return isPermissionAvailable;
  };

  const backButtonClick = () => {
    navigate(CommonHelper.DefaultPermissionRoute);
    setMenuItems([...mainMenuItems]);
  };

  const handleChangeBrand = (brandId) => {
    handleRouteLoading(false);
    let data = CommonHelper.GetBrandInfoFromLocalStorage();
    if (data) {
      SessionStorage.setItem("current_brand_id", JSON.stringify(brandId));
      let permissions = data.brand_details.find(a => a.brand_id === brandId);
      if (permissions) {
        setBrandPermissions(permissions.permission_details);
      }
      LocalStorage.setItem('initial-load', JSON.stringify(data));
    }
  }

  return (
    <>
      {isRouteLoaded ?
        isDesktop ?
          <>
            {headerName && <Header headerName={headerName} />}
            {showSideBar ?
              <div style={{ display: 'flex', flexDirection: 'row', minHeight: '100%', height: '100vh' }}>
                <ProfileSection handleChangeBrand={handleChangeBrand} handleInitCall={handleInitCall} />

                <MenuBarComponent
                  isMainMenuBarView={isMainMenuBarView}
                  menuItems={menuItems}
                  backButtonClick={backButtonClick}
                />
                <MainPage pathname={location.pathname} routes={routes} />
              </div>
              :
              <MainPage pathname={location.pathname} routes={routes} />
            }
          </>
          :
          <>
            {headerName && <MobileHeader handleChangeBrand={handleChangeBrand} headerName={headerName} />}
            <MainPage isDesktop={false} pathname={location.pathname} routes={routes} />
            {headerName && <Footer>
              <FooterSection />
            </Footer>}
          </>
        :
        <>
          <Spinner />
        </>
      }
    </>
  );
};
export default MainComponent;
