import { useTheme } from '@emotion/react';
import { Hidden, TableBody, useMediaQuery, ListItemText } from '@mui/material';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import { CommonHelper } from 'src/utils/commonHelper';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import PendingApprovaldGridAction from './pending-approval-grid-action.component';
import PostPreview from 'src/pages/scheduledPost/Preview/post-preview.component';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';

const PendingApprovalGridBody = ({ pendingApprovalList, page, rowsPerPage, handleReCallPendingApprovalPost }) => {
  const theme = useTheme();
  let maxWidth = useMediaQuery(theme.breakpoints.down('sm')) ? 500 : 400;
  const { showToast } = useToaster();
  const handleOpenScheduledPost = (row, open = false) => {
    let data = {
      content: postView,
      data: row,
    };
    showRightSidebar(data, '500px');
  };

  const postView = (row) => {
    let data = {
      id: row,
    };
    let postDetails = {
      data: data,
    };
    return <PostPreview postDetails={postDetails}></PostPreview>;
  };

  const { showRightSidebar } = useRightSidebar();

  const toggleScheduledPostSuccessCallback = (data, message) => {
    showToast(message, 's');
    handleReCallPendingApprovalPost();
  };

  const toggleScheduledPostFailureCallback = (message) => {
    showToast(message, 'e');
    handleReCallPendingApprovalPost();
  };

  return (
    <>
      <TableBody>
        {pendingApprovalList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
          return (
            <>
              <StyledComponent.StyledTableRow
                onClick={() => {
                  handleOpenScheduledPost(row.id, true);
                }}
              >
                <StyledComponent.StyledTableCell>
                  {CommonHelper.getTableDateAndTime(row.scheduled_time)}
                </StyledComponent.StyledTableCell>
                <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell>
                    <ListItemText
                      sx={{
                        m: 0,
                        '.MuiListItemText-primary': {
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontSize: '0.875rem',
                        },
                        '.MuiListItemText-secondary': {
                          color: '#637381',
                          fontSize: '0.75rem',
                          textTransform: 'lowercase',
                        },
                      }}
                      primary={CommonHelper.formatDateAndTime(row.created_time, 'date')}
                      secondary={CommonHelper.formatDateAndTime(row.created_time, 'time')}
                    />
                  </StyledComponent.StyledTableCell>
                </Hidden>
                <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell>
                    <ListItemText
                      sx={{
                        m: 0,
                        '.MuiListItemText-primary': {
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontSize: '0.875rem',
                        },
                        '.MuiListItemText-secondary': {
                          color: '#637381',
                          fontSize: '0.75rem',
                          textTransform: 'lowercase',
                        },
                      }}
                      primary={CommonHelper.formatDateAndTime(row.updated_at, 'date')}
                      secondary={CommonHelper.formatDateAndTime(row.updated_at, 'time')}
                    />
                  </StyledComponent.StyledTableCell>
                </Hidden>
                <StyledComponent.StyledTableCell>
                  {PostDetailsHelper.GetPostContent(row.description, maxWidth)}
                </StyledComponent.StyledTableCell>
                <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell>
                    {PostDetailsHelper.getPostMedias(row.media_url)}
                  </StyledComponent.StyledTableCell>
                </Hidden>
                <StyledComponent.StyledTableCell>
                  {PostDetailsHelper.getProfile(row.created_by.name, row.created_by.email, row.created_by.profile_pic)}
                </StyledComponent.StyledTableCell>
                <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell>
                    {PostDetailsHelper.getSocialMediaViewWithTooltip(row.connected_profile_details)}
                  </StyledComponent.StyledTableCell>
                </Hidden>
                <StyledComponent.StyledTableCell align='center'  onClick={(event) => event.stopPropagation()}>
                  <PendingApprovaldGridAction
                    approvePost={row}
                    handleReCallPendingApprovalPost={handleReCallPendingApprovalPost}
                  />
                </StyledComponent.StyledTableCell>
              </StyledComponent.StyledTableRow>
            </>
          );
        })}
      </TableBody>
    </>
  );
};

export default PendingApprovalGridBody;
