import {
  Box,
  Card,
  Stack,
  Typography,
  TextField,
  Grid,
  IconButton,
  Button,
  useMediaQuery,
  createTheme,
} from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import Label from '../../components/Label';
import GppBadIcon from '@mui/icons-material/GppBad';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { CommonHelper } from 'src/utils/commonHelper';

const RootStyle = styled(Card)(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(5),
  },
  // boxShadow: theme.customShadows.z8,
  borderWidth: `1px !important`,
  borderColor: `${alpha(theme.palette.grey[500], 1)} !important`,

  // Adding transition for smooth effect
  transition: 'transform 0.3s ease-out',
}));

const PricingPlanCard = ({
  name,
  price,
  no_of_scheduled_posts,
  no_of_draft,
  no_of_social_account,
  id,
  selectedPlan,
  handlePlan,
  is_current_plan,
  plan_validity,
  no_of_users,
  no_of_brands,
  no_of_scheduled_days_ahead,
  total_storage,
  has_cname,
  handleUpdate,
  planAddonDetials,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const customTheme = createTheme({
    breakpoints: {
      values: {
        mdCustom: 1100,
      },
    },
  });

  const responsiveFontSize = useMediaQuery(theme.breakpoints.between('md', 'xl'));

  const planDetailsFontSize = () => {
    if (responsiveFontSize) return '1rem';
    return '1rem';
  };
  const getInitialStruct = planAddonDetials.map((addon) => {

    let Price;
    switch (addon.name) {
      case 'no_of_social_accounts':
        Price = no_of_social_account;
        break;
      case 'no_of_users':
        Price = no_of_users;
        break;
      case 'no_of_brands':
        Price = no_of_brands;
        break;
      case 'no_of_scheduled_posts':
        Price = no_of_scheduled_posts;
        break;
      case 'no_of_drafts':
        Price = no_of_draft;
        break;
      case 'no_of_storage':
        Price = total_storage;
        break;
      default:
        Price = 0;
        break;
    }
    return {
      Value: addon.name,
      DisplayName: addon.name,
      Quantity: addon.quantity,
      Price: Price,
    };
  });
  const UpgradeStruct = {
    no_of_social_accounts: 0,
    no_of_users: 0,
    no_of_brands: 0,
    no_of_scheduled_posts: 0,
    no_of_drafts: 0,
    no_of_storage: 0,
    has_c_name: false,
  };

  const [addons, setAddons] = useState(getInitialStruct);
  const [upgradeItems, setUpgradeItems] = useState(UpgradeStruct);

  const handleInputChange = (value, addon) => {

    setUpgradeItems((prevItems) => ({
      ...prevItems,
      [addon.Value]: value,
    }));
  };

  const incrementValue = (addon) => {
    handleInputChange(upgradeItems[addon.Value] + 1, addon);
  };

  const decrementValue = (addon) => {
    handleInputChange(Math.max(0, upgradeItems[addon.Value] - 1), addon); // Ensure value doesn't go below 0
  };
  const handleUpdateAddon = () => {
    handleUpdate(upgradeItems);
  };
  const handleChangePlan = (id) => {
    setUpgradeItems({ ...UpgradeStruct });
    handlePlan(id);
  };
  const handleReset = () => {
    setUpgradeItems({ ...UpgradeStruct });
    handleUpdate({ ...UpgradeStruct });
  };

  const getBackGroundColor = () => {
    const lightGreen = theme.palette.pricing.lightGreen;
    const lightRed = `linear-gradient(140deg, #00C853 0%, #B9F6CA 100%)`;
    return is_current_plan ? lightRed : selectedPlan?.id === id ? lightGreen : '';
  };

  return (


    <Grid container sx={{ p: 1 }}>
      {/* Main Plan Details */}
      <Grid item xs={12} md={selectedPlan?.id === id ? 7 : 12}>
        <RootStyle style={{ background: getBackGroundColor() }} onClick={() => handleChangePlan(id)}>
          {is_current_plan && (
            <Label sx={{ top: { xs: 4, sm: 16 }, right: { xs: 8, sm: 16 }, position: 'absolute', background: 'white' }}>
              CURRENT PLAN
            </Label>
          )}
          <Typography variant="overline" sx={{ color: 'text.secondary' }}>
            {name.replace('_', ' ')}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
            <Typography variant="h4" sx={{ mx: 1 }}>
              {price === 0 ? 'Free' : `$${price}`}
            </Typography>
            {price !== 0 && (
              <Typography
                gutterBottom
                component="span"
                variant="subtitle2"
                sx={{ alignSelf: 'flex-end', color: 'text.secondary' }}
              >
                /{plan_validity === 30 ? 'MONTH' : 'YEAR'}LY
              </Typography>
            )}
          </Box>

          <Stack component="ul" spacing={2} sx={{ my: 2 }}>
            <Stack component="li" direction="row" alignItems="center" spacing={1.5}>
              <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
              <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                {no_of_social_account} Social Profiles
              </Typography>
            </Stack>
            <Stack
              component="li"
              direction="row"
              alignItems="center"
              spacing={1.5}
              sx={{ typography: 'body2', color: 'text.primary' }}
            >
              <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
              <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                {no_of_users} Number of Users
              </Typography>
            </Stack>
            <Stack
              component="li"
              direction="row"
              alignItems="center"
              spacing={1.5}
              sx={{ typography: 'body2', color: 'text.primary' }}
            >
              <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
              <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                {no_of_brands} Number of Brand
              </Typography>
            </Stack>
            <Stack
              component="li"
              direction="row"
              alignItems="center"
              spacing={1.5}
              sx={{ typography: 'body2', color: 'text.primary' }}
            >
              <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
              <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                {no_of_scheduled_posts} Scheduled Posts
              </Typography>
            </Stack>
            <Stack
              component="li"
              direction="row"
              alignItems="center"
              spacing={1.5}
              sx={{ typography: 'body2', color: 'text.primary' }}
            >
              <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
              <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                {no_of_scheduled_days_ahead} Scheduled Posts Ahead
              </Typography>
            </Stack>
            <Stack
              component="li"
              direction="row"
              alignItems="center"
              spacing={1.5}
              sx={{ typography: 'body2', color: 'text.primary' }}
            >
              <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
              <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                {no_of_draft} Drafts
              </Typography>
            </Stack>
            <Stack
              component="li"
              direction="row"
              alignItems="center"
              spacing={1.5}
              sx={{ typography: 'body2', color: 'text.primary' }}
            >
              <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
              <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                {total_storage} GB Storage
              </Typography>
            </Stack>
            <Stack
              component="li"
              direction="row"
              alignItems="center"
              spacing={1.5}
              sx={{ typography: 'body2', color: 'text.primary' }}
            >
              {has_cname ? (
                <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
              ) : (
                <GppBadIcon sx={{ color: 'error.main', width: 20, height: 20 }} />
              )}
              <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                White Label
              </Typography>
            </Stack>
          </Stack>
        </RootStyle>
      </Grid>

      {selectedPlan?.id === id && (
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            transform: selectedPlan?.id === id ? 'translateY(0)' : 'translateY(100%)',
            transition: 'transform 0.3s ease-out',
            backgroundColor: 'background.default',
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            ...(isMobile && {
              justifyContent: 'center',
              alignItems: 'center',
            }),
            zIndex: 111
          }}
        >
          <Stack>
            <Typography variant="overline" sx={{ color: 'text.secondary', pb: 2, pt: 2 }}>
              Add-on plan
            </Typography>
            {addons &&
              addons.filter((addon) => !["no_of_draft", "has_cname"].includes(addon.Value))
                .map((addon, index) => (
                  // <Box>
                  //   <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                  //     {addon.DisplayName}
                  //   </Typography>
                  //   <Stack direction="row" alignItems="center" sx={{ color: 'text.primary' }}>
                  //     <IconButton size="small" onClick={() => decrementValue(addon)} sx={{ fontSize: '0.875rem' }}>
                  //       <RemoveIcon />
                  //     </IconButton>
                  //     <Typography variant="body2" sx={{ fontSize: '0.875rem', width: '60px', textAlign: 'center' }}>
                  //       {(upgradeItems[addon.Value] * addon.Quantity) + addon.Price}
                  //     </Typography>
                  //     <IconButton size="small" onClick={() => incrementValue(addon)} sx={{ fontSize: '0.875rem' }}>
                  //       <AddIcon />
                  //     </IconButton>
                  //   </Stack>
                  // </Box>
                  <Box key={addon.Value} sx={{ mb: 1, pl: 2, pr: 2 }}>
                    <Typography>{CommonHelper.DisplayPlanAddonName(addon.DisplayName)}</Typography>
                    <Box
                      key={addon.Value}
                      display="flex"
                      alignItems="center"
                      mb={1}
                      sx={{
                        borderRadius: '4px',
                        padding: '4px',
                        justifyContent: 'space-between',
                        backgroundColor: 'background.default',
                      }}
                    >
                      <IconButton
                        onClick={() => decrementValue(addon)}
                        sx={{
                          color: 'black',
                          backgroundColor: 'white',
                          borderRadius: 1,
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Typography variant="body2" sx={{ fontSize: '0.875rem', width: '60px', textAlign: 'center' }}>
                        {(upgradeItems[addon.Value] * addon.Quantity)}
                      </Typography>
                      <IconButton
                        onClick={() => incrementValue(addon)}
                        sx={{
                          color: 'black',
                          backgroundColor: 'white',
                          borderRadius: 1,
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </Box>
                ))}

          </Stack>
          <Stack spacing={0.5}>
            <Button variant="contained" onClick={handleUpdateAddon}>
              Save
            </Button>
            <Button variant="contained" onClick={handleReset}>
              Reset
            </Button>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default PricingPlanCard;
